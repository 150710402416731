/* Copyright G. Hemingway, @2023 - All rights reserved */
"use strict";

import React from "react";
import styled from "styled-components";

const LandingBase = styled.div`
  display: flex;
  justify-content: center;
  grid-area: main;
`;

export const Landing = () => (
  <LandingBase>
    <div>
      <div>
        {" "}
        Enable modification of a user's profile: implemented successfully
      </div>

      <div> Fully working "results" page: implemented successfully</div>

      <div>clickable movements: partially working</div>

      <div> AutoComplete: tried but not working :( </div>

      <div> Github Authentication: only the first step worked :( </div>

      <div>Recognize end of game: will recognize if you win </div>

      <div>Infinite undo/redo: partially working </div>
    </div>
  </LandingBase>
);
