/* Copyright G. Hemingway, @2023 - All rights reserved */
"use strict";

console.error = function () {};
console.warn = function () {};
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Pile } from "./pile.js";

const CardRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 2em;
`;

const CardRowGap = styled.div`
  flex-grow: 2;
`;

const GameBase = styled.div`
  grid-row: 2;
  grid-column: sb / main;
  height: 100vh;
`;

const HighlightedCard = styled.div`
  /* Style for highlighting the card */
  border: 2px solid blue; // or another style to highlight the card
`;

const Popup = ({ isOpen, closePopup, message }) => {
  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: "20%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        padding: "20px",
        zIndex: 1000,
      }}
    >
      <div>{message}</div>
      <button onClick={closePopup}>Close</button>
    </div>
  );
};

export const Game = () => {
  const { id } = useParams();
  let [state, setState] = useState({
    pile1: [],
    pile2: [],
    pile3: [],
    pile4: [],
    pile5: [],
    pile6: [],
    pile7: [],
    stack1: [],
    stack2: [],
    stack3: [],
    stack4: [],
    draw: [],
    discard: [],
  });
  let [target, setTarget] = useState(undefined);
  // let [startDrag, setStartDrag] = useState({ x: 0, y: 0 });
  const [selectedCards, setSelectedCards] = useState(null);
  const [destination, setDestination] = useState(null);
  const [source, setSource] = useState(null);
  const [drawCount, setDrawCount] = useState(1);
  const [numMoves, setNumMoves] = useState(0);
  const [popupOpen, setPopupOpen] = useState(false);
  const [message, setMessage] = useState("");

  const showPopup = (msg) => {
    setMessage(msg);
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
    setMessage("");
  };

  useEffect(() => {
    if (popupOpen) {
      closePopup();
    }
  }, [state]);

  useEffect(() => {
    const getGameState = async () => {
      const response = await fetch(`/v1/game/${id}`);
      const data = await response.json();
      setDrawCount(data.drawCount);
      setState({
        pile1: data.pile1,
        pile2: data.pile2,
        pile3: data.pile3,
        pile4: data.pile4,
        pile5: data.pile5,
        pile6: data.pile6,
        pile7: data.pile7,
        stack1: data.stack1,
        stack2: data.stack2,
        stack3: data.stack3,
        stack4: data.stack4,
        draw: data.draw,
        discard: data.discard,
      });
    };
    getGameState();
  }, [id]);

  useEffect(() => {
    checkForEndGame();
  }, [state]);

  useEffect(() => {
    if (source && destination) {
      if (selectedCards) {
        constructMoveRequest(selectedCards, source, destination);
      }

      setSelectedCards(null);
      setDestination(null);
      setSource(null);
    }
  }, [source, destination, selectedCards]);

  const constructMoveRequest = async (
    selectedCards,
    sourcePile,
    destinationPile
  ) => {
    if (sourcePile && destinationPile) {
      let moveRequestObject = {
        cards: selectedCards,
        src: sourcePile,
        dst: destinationPile,
      };
      console.log(JSON.stringify(moveRequestObject));

      // send put request to the server
      try {
        const response = await fetch(`/v1/game/${id}/move`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify(moveRequestObject),
        });

        const result = await response.json();
        if (response.ok) {
          setState(result);
          setNumMoves(numMoves + 1);
        } else {
          // Handle error response
          console.error("Move validation error:", result.error);
          // Restore visual state or show error message to user
        }
      } catch (error) {
        console.error("Network error:", error);
        // Handle network error
      }
    }
  };

  const executeMove = async (move) => {
    const { from, to, card } = move;
    const selectedCards = [card];
    await constructMoveRequest(selectedCards, from, to);
  };

  // define onClick method for the cards
  const onClick = (ev, pileId) => {
    ev.stopPropagation();
    const clickedCard = ev.target.id;
    const [suit, value] = clickedCard.split(":");
    const pile = state[pileId];
    const clickedCardIndex = pile.findIndex(
      (card) => card.suit === suit && card.value === value
    );

    if (pileId == "draw") {
      if (pile.length != 0) {
        const cardsToSelect = pile.slice(-drawCount).map(({ suit, value }) => {
          return { suit, value };
        });
        setSelectedCards(cardsToSelect);
        setSource("draw");
        setDestination("discard");
        // when the draw pile is empty
      } else {
        setSelectedCards([{ suit: "empty", value: "empty" }]);
        setSource("draw");
        setDestination("discard");
      }
    }

    if (!selectedCards) {
      if (pileId != "draw" && pile[clickedCardIndex].up) {
        // Select all cards from the clicked card to the end of the pile{
        const cardsToSelect = pile
          .slice(clickedCardIndex)
          .map(({ suit, value }) => {
            return { suit, value };
          });
        setSelectedCards(cardsToSelect);
        setSource(pileId);
      }
    } else {
      setDestination(pileId);
    }
  };

  const onBackgroundClick = () => {
    setSelectedCards(null);
    setSource(null);
    setDestination(null);
  };

  const findValidMove = (state) => {
    // Check for moves from pile to foundation
    for (const tableauPile of [
      "pile1",
      "pile2",
      "pile3",
      "pile4",
      "pile5",
      "pile6",
      "pile7",
    ]) {
      const pile = state[tableauPile];
      if (pile.length === 0) continue;

      const topCard = pile[pile.length - 1];
      for (const foundationStack of ["stack1", "stack2", "stack3", "stack4"]) {
        if (isValidMoveToFoundation(topCard, state[foundationStack])) {
          return { from: tableauPile, to: foundationStack, card: topCard };
        }
      }

      // Check for moves within tableau
      for (const targetPile of [
        "pile1",
        "pile2",
        "pile3",
        "pile4",
        "pile5",
        "pile6",
        "pile7",
      ]) {
        if (tableauPile === targetPile) continue; // Skip same pile

        const targetTopCard = state[targetPile][
          state[targetPile].length - 1
        ] || { value: "K", suit: null }; // Consider empty pile as having a King
        if (isValidMoveWithinTableau(topCard, targetTopCard)) {
          return { from: tableauPile, to: targetPile, card: topCard };
        }
      }
    }

    // Check for moves from discard to tableau
    if (state.discard.length > 0) {
      const topDiscardCard = state.discard[state.discard.length - 1];

      // Check if the top card of the discard pile can be moved to any foundation pile
      for (const foundationStack of ["stack1", "stack2", "stack3", "stack4"]) {
        if (isValidMoveToFoundation(topDiscardCard, state[foundationStack])) {
          return { from: "discard", to: foundationStack, card: topDiscardCard };
        }
      }

      // Check if the top card of the discard pile can be moved to any tableau pile
      for (const tableauPile of [
        "pile1",
        "pile2",
        "pile3",
        "pile4",
        "pile5",
        "pile6",
        "pile7",
      ]) {
        const tableauTopCard = state[tableauPile][
          state[tableauPile].length - 1
        ] || { value: "K", suit: null };
        if (isValidMoveWithinTableau(topDiscardCard, tableauTopCard)) {
          return { from: "discard", to: tableauPile, card: topDiscardCard };
        }
      }
    }
    return null; // No valid move found
  };

  const isValidMoveToFoundation = (card, foundationStack) => {
    if (foundationStack.length === 0 && card.value === "A") return true; // Ace can start a new foundation stack
    if (foundationStack.length === 0) return false;

    const topFoundationCard = foundationStack[foundationStack.length - 1];
    return (
      card.suit === topFoundationCard.suit &&
      card.value === topFoundationCard.value + 1
    );
  };

  const isValidMoveWithinTableau = (card, targetCard) => {
    const oppositeColor = (suit) =>
      suit === "Hearts" || suit === "Diamonds"
        ? ["Clubs", "Spades"]
        : ["Hearts", "Diamonds"];
    return (
      oppositeColor(card.suit).includes(targetCard.suit) &&
      card.value === targetCard.value - 1
    );
  };

  const checkForEndGame = () => {
    if (
      state.stack1.length === 13 &&
      state.stack2.length === 13 &&
      state.stack3.length === 13 &&
      state.stack4.length === 13
    ) {
      console.log("end");
      showPopup("Congratulations! You've won the game!");
      return;
    } else if (findValidMove(state) == false) {
      console.log("You reached the end of the game...");
    }
  };

  const handleAutocomplete = async () => {
    let move;
    do {
      move = findValidMove(state);
      if (move) {
        await executeMove(move);
        // await new Promise((resolve) => setTimeout(resolve, 500)); // adjust delay as needed
      }
      if (move == null) {
        console.log(move);
      }
    } while (move);
  };

  const performAction = async (action) => {
    try {
      const response = await fetch(`/v1/game/${id}/${action}`, {
        method: "GET",
      });

      if (!response.ok) {
        // Extract and throw an error with the response's error message
        const errorData = await response.json();
        throw new Error(errorData.error || "Server responded with an error!");
      }

      const data = await response.json();
      setState(data); // Update the parent component's state
    } catch (error) {
      console.error("Error:", error.message);
      showPopup("No Further Actions Can be Done!");
    }
  };

  return (
    <GameBase onClick={onBackgroundClick}>
      <button onClick={handleAutocomplete}>AutoComplete</button>
      <button onClick={() => performAction("undo")}>Undo</button>
      <button onClick={() => performAction("redo")}>Redo</button>

      <Popup isOpen={popupOpen} closePopup={closePopup} message={message} />
      <CardRow>
        <Pile
          id="stack1"
          cards={state.stack1}
          spacing={0}
          onClick={(ev) => onClick(ev, "stack1")}
        />
        <Pile
          id="stack2"
          cards={state.stack2}
          spacing={0}
          onClick={(ev) => onClick(ev, "stack2")}
        />
        <Pile
          id="stack3"
          cards={state.stack3}
          spacing={0}
          onClick={(ev) => onClick(ev, "stack3")}
        />
        <Pile
          id="stack4"
          cards={state.stack4}
          spacing={0}
          onClick={(ev) => onClick(ev, "stack4")}
        />
        <CardRowGap />
        <Pile
          id="draw"
          cards={state.draw}
          spacing={0}
          onClick={(ev) => onClick(ev, "draw")}
        />
        <Pile
          id="discard"
          cards={state.discard}
          spacing={0}
          onClick={(ev) => onClick(ev, "discard")}
        />
      </CardRow>
      <CardRow>
        <Pile
          id="pile1"
          cards={state.pile1}
          onClick={(ev) => onClick(ev, "pile1")}
        />
        <Pile
          id="pile2"
          cards={state.pile2}
          onClick={(ev) => onClick(ev, "pile2")}
        />
        <Pile
          id="pile3"
          cards={state.pile3}
          onClick={(ev) => onClick(ev, "pile3")}
        />
        <Pile
          id="pile4"
          cards={state.pile4}
          onClick={(ev) => onClick(ev, "pile4")}
        />
        <Pile
          id="pile5"
          cards={state.pile5}
          onClick={(ev) => onClick(ev, "pile5")}
        />
        <Pile
          id="pile6"
          cards={state.pile6}
          onClick={(ev) => onClick(ev, "pile6")}
        />
        <Pile
          id="pile7"
          cards={state.pile7}
          onClick={(ev) => onClick(ev, "pile7")}
        />
      </CardRow>
    </GameBase>
  );
};

Game.propTypes = {};
