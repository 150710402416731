import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  position: absolute; // Use absolute positioning
  top: 30%; // Align the top edge to the middle of the page
  right: 10%; // Position 10% from the right edge of the page
  transform: translateY(
    -50%
  ); // Offset the container by half its height for vertical centering
  width: 400px; // Set a specific width (adjust as needed)
  padding: 20px; // Add padding (optional)
  border-radius: 8px; // Rounded corners (optional)
`;

const Title = styled.h2`
  color: #444;
  margin-bottom: 30px;
  text-align: center;
`;

const Form = styled.form`
  display: grid;
  grid-gap: 40px;
  padding: 100px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const Label = styled.label`
  font-size: 1rem;
  margin-bottom: 10px;
  color: #333;
`;

const Input = styled.input`
  padding: 12px 15px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
`;

const Button = styled.button`
  margin-left: 180px;
  background-color: #007bff;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  transition: background-color 0.3s;
`;

export const Edit = () => {
  const { username } = useParams();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    city: "",
  });

  const handleInput = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`/v1/user`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        window.location.href = `/profile/${username}`;
      } else {
        console.error("there is an error with updating profile");
      }
    } catch (error) {
      console.error("there is an error with updating profile", error);
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <div>
          <Label htmlFor="first_name">First Name:</Label>
          <Input
            type="text"
            id="first_name"
            name="first_name"
            value={formData.first_name}
            onChange={handleInput}
          />
        </div>
        <div>
          <Label htmlFor="last_name">Last Name:</Label>
          <Input
            type="text"
            id="last_name"
            name="last_name"
            value={formData.last_name}
            onChange={handleInput}
          />
        </div>
        <Label htmlFor="city">City:</Label>
        <Input
          type="text"
          id="city"
          name="city"
          value={formData.city}
          onChange={handleInput}
        />
        <div>
          <Button type="submit">Save</Button>
        </div>
      </form>
    </Container>
  );
};
