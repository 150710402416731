/* Copyright G. Hemingway, @2023 - All rights reserved */
"use strict";

import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import { ErrorMessage, InfoBlock, InfoData, InfoLabels } from "./shared.js";

const Move = ({ move, index, timeSpent, id }) => {
  const duration = Date.now() - move.date;
  const moveDetail = `${move.cards[0].value} of ${move.cards[0].suit} from ${move.src} to ${move.dst}`;

  return (
    <tr>
      {/* <th>{move.id ? move.id : index + 1}</th> */}
      <Link to={`/game/${id}/${index + 1}`}> {index + 1}</Link>

      <th>{timeSpent} seconds</th>
      <th>
        <Link to={`/profile/${move.user}`}>{move.user}</Link>
      </th>
      <th>{moveDetail}</th>
    </tr>
  );
};

const lastMoveDetail = (move) => {
  let card = move.cards[0];

  return `${card.value} of ${card.suit} from ${move.src} to ${move.dst}`;
};

Move.propTypes = {
  move: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

const MovesListTable = styled.table`
  margin: 1em;
  width: 90%;
  min-height: 4em;
  border: 1px solid black;
  text-align: center;
  @media (max-width: 499px) {
    & > tbody > tr > td:nth-of-type(2),
    & > thead > tr > th:nth-of-type(2) {
      display: none;
    }
  }
`;

const MovesList = ({ moves, start, id }) => {
  const timeGaps = moves.map((move, index) => {
    if (index == 0) {
      return Math.round((new Date(move.date).getTime() - start) / 1000);
    } else {
      return Math.round(
        (new Date(move.date).getTime() -
          new Date(moves[index - 1].date).getTime()) /
          1000
      );
    }
  });

  let moveElements = moves.map((move, index) => (
    <Move
      key={index}
      id={id}
      move={move}
      index={index}
      timeSpent={timeGaps[index]}
    />
  ));
  return (
    <MovesListTable>
      <thead>
        <tr>
          <th>Id</th>
          <th>Duration</th>
          <th>Player</th>
          <th>Move Details</th>
        </tr>
      </thead>
      <tbody>{moveElements}</tbody>
    </MovesListTable>
  );
};

const GameDetail = ({ start, moves, score, cards_remaining, active }) => {
  const duration = start ? (Date.now() - start) / 1000 : "--";
  return (
    <InfoBlock>
      <InfoLabels>
        <p>Duration:</p>
        <p>Number of Moves:</p>
        <p>Points:</p>
        <p>Cards Remaining:</p>
        <p>Able to Move:</p>
      </InfoLabels>
      <InfoData>
        <p>{duration} seconds</p>
        <p>{moves.length}</p>
        <p>{score}</p>
        <p>{cards_remaining}</p>
        <p>{active ? "Yes" : "No"}</p>
      </InfoData>
    </InfoBlock>
  );
};

GameDetail.propTypes = {
  start: PropTypes.number.isRequired,
  moves: PropTypes.array.isRequired,
  score: PropTypes.number.isRequired,
  cards_remaining: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
};

const ResultsBase = styled.div`
  grid-area: main;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Results = () => {
  const { id } = useParams();
  // Initialize the state
  let [game, setGame] = useState({
    start: 0,
    score: 0,
    cards_remaining: 0,
    active: true,
    moves: [],
  });
  let [error, setError] = useState("");
  // Fetch data on load
  useEffect(() => {
    fetch(`/v1/game/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setGame(data);
      })
      .catch((err) => console.log(err));
  }, [id]);

  return (
    <ResultsBase>
      <ErrorMessage msg={error} hide={true} />
      <h4>Game Detail</h4>
      <GameDetail {...game} />
      <MovesList moves={game.moves} start={game.start} id={id} />
    </ResultsBase>
  );
};
