/* Copyright G. Hemingway, @2023 - All rights reserved */
"use strict";

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  ErrorMessage,
  FormBase,
  FormLabel,
  FormInput,
  FormButton,
} from "./shared.js";

const CLIENT_ID = "14f5a80130922455706e";
// const [rerender, setRerender] = useState(false);

export const Login = (props) => {
  let navigate = useNavigate();
  let [username, setUser] = useState("");
  let [password, setPass] = useState("");
  let [error, setError] = useState("");

  const onSubmit = async (ev) => {
    ev.preventDefault();
    let res = await fetch("/v1/session", {
      body: JSON.stringify({
        username,
        password,
      }),
      method: "POST",
      credentials: "include",
      headers: {
        "content-type": "application/json",
      },
    });
    const data = await res.json();
    if (res.ok) {
      props.logIn(data.username);
      navigate(`/profile/${data.username}`);
    } else {
      setError(`Error: ${data.error}`);
    }
  };

  useEffect(() => {
    document.getElementById("username").focus();
  }, []);

  useEffect(() => {
    // localhost:8080/auth/github?code=dcb82ed8ab85fe477384
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const codeParam = urlParams.get("code");
    console.log(queryString);
    console.log(codeParam);

    // if (codeParam && localStorage.getItem("accessToken") === null) {
    //   async function getAccessToken() {
    //     await fetch("http://localhost:8080/getAccessToken?code=" + codeParam, {
    //       method: "GET",
    //     })
    //       .then((response) => {
    //         return response.json();
    //       })
    //       .then((data) => {
    //         console.log(data);
    //         if (data.access_token) {
    //           localStorage.setItem("accessToken", data_access_token);
    //           // setRerender(!rerender);
    //         }
    //       });
    //   }
    //   getAccessToken();
    // }
  }, []);

  function loginWithGithub() {
    window.location.assign(
      "http://github.com/login/oauth/authorize?client_id=" + CLIENT_ID
    );
  }

  return (
    <div style={{ gridArea: "main" }}>
      <ErrorMessage msg={error} />
      <FormBase>
        <FormLabel htmlFor="username">Username:</FormLabel>
        <FormInput
          id="username"
          name="username"
          type="text"
          placeholder="Username"
          value={username}
          onChange={(ev) => setUser(ev.target.value.toLowerCase())}
        />

        <FormLabel htmlFor="password">Password:</FormLabel>
        <FormInput
          id="password"
          name="password"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(ev) => setPass(ev.target.value)}
        />
        <div />
        <FormButton id="submitBtn" onClick={onSubmit}>
          Login
        </FormButton>
      </FormBase>
      <button onClick={loginWithGithub}>Login with Github</button>
    </div>
  );
};

Login.propTypes = {
  logIn: PropTypes.func.isRequired,
};
